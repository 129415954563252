.search-bar {
    height: 30px;
    display: flex;
    flex-direction: row;
    padding: 1px;
}

.search-bar-input {
    flex-shrink: 1;
    flex-grow: 1;
    padding: 0 5px;
}

.search-bar-button {
    flex-shrink: 0;
    flex-grow: 0;
}