.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 0.5;
}

.dialog {
    position: fixed;
    left: calc(50vw - 112px);
    top: calc(50vh - 62px);
    padding: 10px;
    width: 200px;
    height: 100px;
    background-color: white;
    border: 2px solid black;
    border-radius: 2px;
}

.dialog-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.dialog-text {
    height: 70px;
}

.dialog-button {

}