.editor-view {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    background-color: lightyellow;
    display: flex;
    flex-direction: row;
}

.navigation {
    margin: 0;
    padding: 5px;
    width: 350px;
    flex-grow: 0;
    flex-shrink: 0;
    height: calc(100vh - 10px);
    display: flex;
    flex-direction: column;
    background-color: gold;
    border-right: 1px solid lightgrey;
    overflow: hidden;
}

.branding {
    height: 80px;
    font-size: 60px;
    line-height: 80px;
    text-align: center;
}

.itemlist {
    background-color: lightgrey;
    flex-grow: 1;
    flex-shrink: 1;
    height: calc(100vh - 200px);
    border: 1px solid lightgrey;
    border-radius: 2px;
    overflow: scroll;
    margin: 1px;
}

.itemlist-status {
    padding-right: 10px;
    text-align: right;
    border: none;
    background-color: gold;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    overflow: scroll;
    margin: 1px 2px;
}

.button-logout {
    margin: 1px;
}

.content {
    height: calc(100vh - 0px);
    padding: 0;
    border: none;
    background-color: lightyellow;
    display: flex;
    flex-direction: column;
    min-width: 350px;
    flex-grow: 1;
    flex-shrink: 1;
}

.datetime {
    border: 1px solid lightgrey;
    height: 38px;
    line-height: 38px;
    display: flex;
    flex-direction: row;
    background-color: whitesmoke;
    padding: 0 10px;
    flex-grow: 0;
    flex-shrink: 0;
}

.keywordseditor {
    background-color: whitesmoke;
    border: 1px solid lightgrey;
    height: 38px;
    line-height: 38px;
    padding: 0 10px;
    overflow: auto;
    white-space: pre;
    flex-grow: 0;
    flex-shrink: 0;
    outline: none;
}

.keywordseditor-invalid {
    color: red;
}

.title {
    background-color: whitesmoke;
    border: 1px solid lightgrey;
    height: 39px;
    line-height: 39px;
    font-weight: bold;
    padding: 0 10px;
    overflow: auto;
    white-space: pre;
    flex-grow: 0;
    flex-shrink: 0;
    outline: none;
}

.editor{
    background-color: white;
    padding: 10px;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 200px;
    overflow: auto;
    border: 1px solid lightgrey;
    margin: 0;
    border-radius: 0;
}

.footer {
    flex-grow: 0;
    flex-shrink: 0;
    height: 30px;
    width: calc(100vw - 20px);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

ul {
    padding: 0 0 0 20px;
    margin: 0;
}

p {
    margin: 0 0 5px 0;
    padding: 0;
}