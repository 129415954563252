.note-item {
    padding: 10px;
    background-color: white;
    margin: 0;
    border-bottom: 1px solid lightgrey;
}

.note-item:last-child {
    border-bottom: none;
}


.note-item-selected {
    background-color: lightyellow;
}

.note-item-datetime {
    font-size: 10px;
}

