div.login-view {
    padding: 0;
    width: 270px;
    margin: calc(50vh - 123px) calc(50vw - 146px);
    border: 1px solid lightgrey;
    border-radius: 2px;
    overflow: hidden;
}

h1 {
    line-height: 40px;
    height: 40px;
    border-bottom: 1px solid lightgrey;
    padding: 10px;
    margin: 0;
    font-size: 35px;
    text-align: left;
}

div.login-form {
    display: grid;
    grid-template-columns: 90px 160px;
    padding: 10px;
    width: 250px;
    overflow: hidden;
}

input.login-view {
    height: 35px;
    line-height: 35px;
    width: 138px;
    margin:  0 0 10px 0;
    padding: 0 10px;
    text-align: left;
    background-color: white;
}


p.login-view {
    line-height: 35px;
    height: 35px;
    padding: 0;
    margin: 1px 0 11px 0;
    text-align: left;
    background: none;
    border-radius: 3px;
}